<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose">
      <div class="hx-page">
        <div> 
          <el-form :model="formModel" :rules="rules" ref="formModel" label-width="100px" >
            <el-row>
              <el-col :span="16">
                <el-form-item label="尺寸" prop="size">
                  <el-select v-model="formModel.size" style="width:100%" clearable placeholder="请选择">
                    <el-option
                      v-for="item in printSize"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <!-- <el-button type="primary" :disabled="disable" v-print="printOption">确 定</el-button> -->
                <el-button type="primary" @click="handleClickPrint">确 定</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div id="print-content">
          <div class="barcode-box">
            <vue-Barcode 
            style="display: flex;justify-content: center; align-items: center;font-weight: bold;width:220px;"
            v-for="(item,index) in printValue" :key="index"
            :value="item" format="CODE128" 
            class="barcode"
            :font-option="option.fontOptions"
            :height="option.height"
            :margin="option.margin"
            :text-margin="option.textMargin"
            :font-size="option.fontSize"></vue-Barcode>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import vueBarcode from 'vue-barcode';
import print from 'vue-print-nb'
import warehouseHelper from '@/api/warehouse'
export default {
  directives:{
    print
  },
  props:{
    printData:{
      type:Array,
      default:() => {
        return []
      }
    }
  },
  components: {vueBarcode},
  name: '',
  data() {
    return {
      dialogVisible:true,
      title:'',
      option:{
        width: 1,
        height: 40,
        // text: 显示的附加文本。
        fontOptions: 'blod',
        // font: 文字字体。
        // text-align: 文本对齐方式。
        // text-position: 文本位置。
        textMargin: 5, // 文字与条形码之间的间距。
        fontSize: 18,
        // background: 背景颜色。
        // lineColor: 线条颜色。
        margin: 15
      },
      disable:true,
      formModel:{
        size:''
      },
      top:0,
      width:'100px',
      height:'40px',
      rules:{
        size: [
            { required: true, message: '请选择尺寸', trigger: 'change' }
          ]
      },
      printValue:[],
      printSize:[{key:53,value:'50*30'},{key:63,value:'60*30'},{key:64,value:'60*40'},{key:85,value:'80*50'}],
      printOption: {
        id: "print-content", // 打印元素的id 不需要携带#号
        preview: false, // 开启打印预览
        previewTitle: "打印预览", // 打印预览标题
        popTitle: "自定义单号", // 页眉标题 默认浏览器标题 空字符串时显示undefined 使用html语言
        // 头部文字 默认空 在节点中添加 DOM 节点， 并用,(Print local range only)分隔多个节点
        // extraHead: "https://***/***.css, https://***/***.css",
        // 新的 CSS 样式表， 并使用,（仅打印本地范围）分隔多个节点
        // extraCss: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        previewBeforeOpenCallback: () => {
          console.log("触发打印预览打开前回调");
        },
        previewOpenCallback: () => {
          console.log("触发打开打印预览回调");
        },
        beforeOpenCallback: () => {
          console.log("触发打印工具打开前回调");
        },
        openCallback: () => {
          console.log("触发打开打印工具回调");
        },
        closeCallback: () => {
          console.log("触发关闭打印工具回调");
        },
        clickMounted: () => {
          this.submitForm()
          window.close()
          console.log("触发点击打印回调");
        },
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    console.log(this.printData,' print data')
    this.printData.map(item => {
      if(item.logisticsOrders && item.logisticsOrders.length) {
        if(item.logisticsOrders[0].custom_no) {
          this.printValue.push(item.logisticsOrders[0].custom_no)
        } else if(item.remark) {
          this.printValue.push(item.remark)
        } else {
          this.printValue.push(item.waybill_number)
        }
      }
    })
    console.log(this.printValue)
  },
  destroyed() {},
  methods: {
    handleClose(){
      this.$emit('CLOSEPRINTCODE')
    },
    submitForm() {
      this.$refs.formModel.validate((valid) => {
        if (valid) {
          console.log('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    base64ToArrayBuffer(base64) {
      var bloBString = window.atob(base64)
      var bloBLen = bloBString.length
      var bytes = new Uint8Array(bloBLen)
        for (var i = 0; i < bloBLen; i++) {
            var ascii = bloBString.charCodeAt(i)
            bytes[i] = ascii
        }
      return bytes
    },
    handleClickPrint(){
      let _params = {
        barcodeList:this.printValue
      }
      if(this.formModel.size) {
        switch (this.formModel.size) {
          case 53:
            _params.width = 50
            _params.height = 30
            break;
          case 63:
            _params.width = 60
            _params.height = 30
            break;
          case 64:
            _params.width = 60
            _params.height = 40
            break;
          case 85:
            _params.width = 80
            _params.height = 50
            break;
        }
        console.log(_params)
        warehouseHelper.generatePdfBarcode(_params).then(res => {
          let fileName = '自定义编号'
          if (res.state) {
            if (typeof window.navigator.msSaveBlob !== 'undefined') {
              window.navigator.msSaveBlob(
                new Blob([this.base64ToArrayBuffer(res.data)], { type: 'application/pdf' }),
                fileName + '.pdf'
              )
            } else {
              let url = window.URL.createObjectURL(
                new Blob([this.base64ToArrayBuffer(res.data)], { type: 'application/pdf' })
              )
              window.open(url)
              this.$emit('CLOSEPRINTCODE')
              // let link = document.createElement('a')
              // link.style.display = 'none'
              // link.href = url
              // link.setAttribute('download', fileName + '.pdf')
              // document.body.appendChild(link)
              // link.click()
              // document.body.removeChild(link) // 下载完成移除元素
              // window.URL.revokeObjectURL(url) // 释放掉blob对象
            }
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$message.error('请选择尺寸')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hx-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  #print-content{
    width:500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .test{
      background: #ccc;
      height: 100px;
      width: 200px;
    }
    .barcode-box{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .barcode{
        background: #fff;
        margin-bottom: 20px;
        padding: 20px;
        text-align: center;
        border: 1px solid #ccc;
      }
    }
  }
}
</style>
