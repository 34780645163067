import { netpost, post, get } from '../plugins/request'
import config from '../config/index';

export default {
  // 获取枚举快递公司
  getLogos(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Warehouse/getLogos`,params);
  },
  // 查询运单信息
  GetWaybillList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Warehouse/GetWaybillList`,params);
  },
  // 异步生成快递面单
  generateCustomizePrintPdfAsynchronous(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/file-download-center/generateCustomizePrintPdfAsynchronous`, params)
  },
  // 查询运单信息
  DownLoadLogisticsWaybillInfoExcel(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Warehouse/DownLoadLogisticsWaybillInfoExcel`,params);
  },
  // 分类转成份
  generatePdfBarcode(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/file-download-center/generatePdfBarcode`, params)
  },
}