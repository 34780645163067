<template>
  <div class="hx-page">
    <div class="search-box">
      <div class="line">
        <div class="content">
          <div>
            <el-input type="textarea" 
            :autosize="{ minRows: 1, maxRows: 4}"
            clearable="" placeholder="请输入内容" 
            style="width: 100%;"
            @blur="setWaybillNo"
            @input="changeWaybillNo"
            v-model="searchTxt"></el-input>
          </div>
          <div class="note">
            <span>支持快递单号/自定义编号/运单号/第三方平台单号</span>
            <span>支持批量搜索，用空格或“，”逗号分隔</span>
          </div>
        </div>
        <div class="buts"><el-button class="mybut" icon="el-icon-search" @click="handleSearch" type="primary">查询运单</el-button></div>
      </div>
      <div class="line border">
        <div class="title">打印配置</div>
      </div>
      <div class="print">
        <div>
          <div class="line">
            <div class="size">
              <div :class="size == item.key ? 'active' : ''" @click="hanldeClickSize(item.key)" v-for="(item,index) in sizeData" :key="index">{{ item.size }}</div>
            </div>
          </div>
          <div class="line">
            <div class="logo">
              <div :class="logo == item.url ? 'active' : ''" @click="handleClickLogo(item.url)" v-for="(item,index) in logoData" :key="index">
                <el-image style=" width: 30px; height: 30px" :src="item.url" fit="cover"></el-image><span>{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="buts">
          <el-button type="primary" class="mybut" icon="el-icon-printer" @click="handlePrint">快递单</el-button>
          <el-button type="primary" class="mybut" icon="el-icon-printer" @click="handlePrintCustom">自定义编号</el-button>
          <el-button type="primary" class="mybut" icon="el-icon-printer" @click="handleExcelWaybill">导出发货单</el-button>
        </div>
      </div>
    </div>
    <div class="table">
      <el-table border="" ref="hxtable" :data="tableData" @selection-change="handleSelectionChange" @row-click="handleClickRow">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column type="index" width="50" label="序号" align="center"></el-table-column>
        <el-table-column label="运单号" prop="waybill_number"></el-table-column>
        <el-table-column label="代理商" prop="AgencyName"></el-table-column>
        <el-table-column label="自定义编号">
          <template slot-scope="scope">
            {{ getCustomNo(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="快递信息">
          <template slot-scope="scope">
            <div>{{ scope.row.deliveryname }}</div>
            <div>{{ scope.row.deliveryno }}</div>
          </template>
        </el-table-column>
        <el-table-column label="收货信息">
          <template slot-scope="scope">
            <div>{{ scope.row.consignee }}({{ scope.row.consignee_tel }})</div>
            <div>{{scope.row.country}}{{scope.row.province}}{{scope.row.city}}{{scope.row.area}}{{ scope.row.consignee_address }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <print-barcode v-if="showPrintCode" :printData="selectData" @CLOSEPRINTCODE="handleClosePrintCode"></print-barcode>
  </div>
</template>

<script>
import printBarcode from './print-barcode.vue'
import warehouseHelper from '@/api/warehouse'
import { GetToken } from 'utils/auth'
export default {
  components: {printBarcode},
  name: '',
  data() {
    return {
      showPrintCode: false,
      logoData:[],
      sizeData:[{key:'SIZE_76_130',size:'76mm * 130mm'},{key:'SIZE_100_150', size:'100mm * 150mm'}],
      tableData:[],
      numbers:'',
      searchTxt:'',
      agency:'',
      logo:'',
      size:'',
      selectData:[]
    }
  },
  computed: {},
  watch: {},
  created() {
    this.printLogoList()
  },
  mounted() {},
  destroyed() {},
  methods: {
    changeWaybillNo() {
      this.searchTxt = this.searchTxt.replace(/ /g, '')
    },
    setWaybillNo() {
      const _no = this.searchTxt.replace(/\n/g, ',').replace(/\s/g, '')
      const _tno = _no.split(',')
      this.numbers = _tno
        .filter((item) => {
          return item !== ''
        })
      this.searchTxt = this.numbers.join(',')
    },
    hanldeClickSize(size) {
      console.log(size)
      this.size = size
    },
    printLogoList(){
      warehouseHelper.getLogos({
        token: GetToken()
      }).then(res => {
        if(res.success) {
          this.logoData = res.record.sort((a,b) => {
            return a.sort - b.sort
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleClickLogo(url) {
      this.logo = url
    },
    getCustomNo(obj){
      let customNo = []
      obj.logisticsOrders.map(item => {
        if(item && item.custom_no){
          customNo.push(item.custom_no)
        }
      })
      return customNo.join(',')
    },
    handleSearch(){
      if(this.numbers && this.numbers.length && this.numbers.length <= 30) {
        warehouseHelper.GetWaybillList({
          token: GetToken(),
          txt: "'"+this.numbers.join("','")+"'"
        }).then(res => {
          if(res.success) {
            this.tableData = res.record
          }
        })
      }
      else {
        this.$message.error('请输入查询单号，最多支持查询30个')
      }
    },
    handleSelectionChange(val){
      this.selectData = val
    },
    handleClickRow(row, column, event) {
      this.$refs.hxtable.toggleRowSelection(row);
    },
    // 打印面单
    handlePrint(){
      if(this.selectData.length < 1) {
        this.$message.error('请选择要打印的运单')
        return false
      }
      if(!this.logo || !this.size) {
        this.$message.error('请选择打印配置')
        return false
      }
      let _printList = []
      _printList = this.selectData.map(item => {
        return {
          bigMark: item.Placeorder ? item.Placeorder.distributeCode : '',
          companyLogoUrl: item.courierLogo,
          companyName: item.Placeorder ? item.Placeorder.deliveryname : '',
          companyProfile: item.Placeorder ? item.Placeorder.deliveryname + ' 海外直邮' : '海外直邮',
          courierCompany: item.Placeorder ? item.Placeorder.deliverytype : '',
          disclaimer:'本快递内容为个人物品，与本公司无关',
          orderNumber: item.waybill_number,
          recipientAddress:item.country + item.province + item.city + item.area + item.consignee_address,
          recipientName: item.consignee,
          recipientPhone: item.consignee_tel,
          senderAddress: item.Agency.address,
          senderName: item.Agency.realname,
          senderPhone: item.Agency.mobile,
          shippingType:item.Agency.deliveryprinttext,
          trackingNumber: item.deliveryno
        }
      })
      warehouseHelper.generateCustomizePrintPdfAsynchronous({
        bottomLogo: this.logo,
        pageSize: this.size,
        shippingList: _printList
      }).then(res => {
        this.$message.success('操作成功，请在下载中心查看！')
      })
    },
    // 打印自定义编码
    handlePrintCustom(){
      if(this.selectData.length < 1) {
        this.$message.error('请选择要打印的运单')
        return false
      }
      this.showPrintCode = true
    },
    // 关闭打印自定义编码
    handleClosePrintCode(){
      this.showPrintCode = false
    },
    // 导出发货单
    handleExcelWaybill(){
      if(this.selectData.length < 1) {
        this.$message.error('请选择要打印的运单')
        return false
      }
      let _waybillNumbers = this.selectData.map(item => {
        return item.waybill_number
      })
      warehouseHelper.DownLoadLogisticsWaybillInfoExcel({
        token: GetToken(),
        waybillnumber:"'"+_waybillNumbers.join("','")+"'"
      }).then(res => {
            let fileName = '运单明细'
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // window.navigator.msSaveBlob(
          //   new Blob([res.record], { type: 'application/zip' }),
          //   fileName + '.zip'
          // )
          window.navigator.msSaveBlob(
            new Blob([this.base64ToArrayBuffer(res.record)], { type: 'application/zip' }),
            fileName + '.zip'
          )
        } else {
          // let url = window.URL.createObjectURL(
          //   new Blob([res.record], { type: 'application/zip' })
          // )
          let url = window.URL.createObjectURL(
            new Blob([this.base64ToArrayBuffer(res.record)], { type: 'application/zip' })
          )
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName + '.zip')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link) // 下载完成移除元素
          window.URL.revokeObjectURL(url) // 释放掉blob对象
        }
      })
    },
    base64ToArrayBuffer(base64) {
      var bloBString = window.atob(base64)
      var bloBLen = bloBString.length
      var bytes = new Uint8Array(bloBLen)
        for (var i = 0; i < bloBLen; i++) {
            var ascii = bloBString.charCodeAt(i)
            bytes[i] = ascii
        }
      return bytes
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hx-page{
  .mybut{
    width: 130px;
  }
  .search-box{
    margin: 10px;
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    .line{
      display: flex;
      .print-box{
        width: 50%;
        padding-right: 20px;
      }
      .content{
        flex:1;
      }
      .note{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
      }
      .buts{
        margin-left: 10px;
      }
      .title{
        font-size: 16px;
        font-weight: bold;
      }
      .size{
        display: flex;
        margin-top: 10px;
        &>div{
          margin-right: 20px;
          border: 1px solid #ccc;
          height: 30px;
          padding: 0 20px;
          display: flex;
          align-items: center;
          border-radius: 4px;
          cursor: pointer;
        }
        &>div.active{
          background: green;
          color: #fff;
        }
      }
      .logo{
        display:flex;
        flex-wrap: wrap;
        &>div{
          display: flex;
          align-items: center;
          margin-right: 20px;
          border: 1px solid #ccc;
          border-radius: 4px;
          cursor: pointer;
          margin-top: 10px;
          span{
            padding: 0 10px;
          }
        }
        &>div.active{
          background: green;
          color: #fff;
        }
      }
    }
    .line.border{
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #eee;
    }
    .print{
      display: flex;
      &>div:first-of-type{
        flex: 1;
      }
      .buts{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        height: 110px;
        margin-top: -22px;
        width: 160px;
      }
    }
  }
}
</style>
