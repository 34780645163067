const warehouse = [
  {
    path: '/warehouse/print/waybill',
    name: 'warehouse-print-waybill',
    component: () => import('@/views/warehouse/print-waybill.vue'),
    meta: {
      title: '快递打印'
    }
  }
]
export default warehouse
// 快递打印 (仓库用)
